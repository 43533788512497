@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

.ReactTable {
  margin: 13px;
}

.react-table {
  text-align: center;
  width: 100%;
}

.importExport {
  height: 40px;
  margin-top: 20px;
}

.headerBand {
  background: #212060;
  color: #FFFFFF;
  width: 380px;
}

.band-left-border {
  background: rgb(160, 207, 255);
  padding: 2px 5px;
}

div.withText {
  position: relative;
  border: 2px solid grey;
  margin-top: 20px;
  padding: 20px 10px 10px;
}

div.withText .labels {
  color: white;
  line-height: 20px;
  padding: 0 5px;
  position: absolute;
  background-color: grey;
  top: -10px;
  left: 10px;
}

.sales_approach_main {
  display: flex;
  flex-direction: column;
}

.sales_approach_sub {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #225798;
  width: 100%;
  height: 7rem;
}

.sales_approach_sub1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #225798;
  width: 100%;
  height: 12rem;
}

.yes_no_red {
  background: rgb(255 86 86);
  color: #fff;
}

.yes_no_green {
  background: rgb(66 197 66);
  color: #fff;
}

.yes_no_partial {
  background: rgb(255 238 91);
  color: #fff;
}

.yes_no {
  height: 3rem;
  text-align-last: center;
  padding: 0px !important;
  margin: 0 !important;
  width: 7.1rem !important;
  color: #333;
  transform: rotate(-90deg);
  margin-left: -3.2rem !important;
  margin-right: 0 !important;

}

.yes_no1 {
  height: 3rem;
  text-align-last: center;
  padding: 0px !important;
  margin: 0 !important;
  width: 12rem !important;
  color: #333;
  transform: rotate(-90deg);
  margin-left: -5.6rem !important;
  margin-right: 0 !important;

}

.da_metric_rating .text_opion {
  color: red !important;
  transform: rotate(-90deg) !important;
}

.sales_approach_heading {
  width: 7rem;
  height: 7rem;
  margin-left: -3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #333;
}

.content_textpart1 span {
  color: #fff;
  padding-left: 0rem;

}

.sales_approach_heading1 {
  width: 7rem;
  height: 12rem;
  margin-left: -5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #333;
}

.sales_approach_heading h6 {
  color: #fff;
  font-weight: bold;
  font-family: 'Lato', sans-serif;
}

.sales_approach_heading1 h6 {
  color: #fff;
  font-weight: bold;
  font-family: 'Lato', sans-serif;
}

.sales_approach_heading1 p {
  color: #fff;
  text-align: center;
  font-size: .7rem;
  font-family: 'Lato', sans-serif;
  white-space: nowrap;
}

.sales_approach_heading p {
  color: #fff;
  text-align: center;
  font-size: .7rem;
  font-family: 'Lato', sans-serif;
  /* white-space: nowrap; */
}

.sales_approach_content {
  border: 1px solid #333;
  height: 7rem;
  width: 35rem;
}

.sales_approach_content1 {
  border: 1px solid #333;
  height: 12rem;
  width: 35rem;
}

.sales_approach_content_extra {
  border: 1px solid #333;
  height: 7rem;
  width: 35rem;
  overflow: auto;
  overflow-y: scroll;
}

.sales_approach_content_extra1 {
  border: 1px solid #333;
  height: 12rem;
  width: 35rem;
  overflow: auto;
  overflow-y: scroll;
}

.sales_approach_content h6 {
  text-align: center;
  font-size: .9rem;
  color: #fff;
}

.about_soluctiondiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.content_textpart {
  width: 100%;
  border-right: 1px solid #333;
  margin-top: .5rem;
}

.dropdown_box {
  margin-top: 1rem !important;
}

.content_imgpart {
  width: 50%;
}

.contact_details {
  width: 100%;
  background: whitesmoke;
  margin-top: .5rem;
  margin-right: .2rem;
  border-radius: 10px;

}

.notes_contacts {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.notes_contacts p {
  color: #fff;
  text-align: center;

}

.notes_div {
  width: 37.5%;
  border-right: 1px solid #333;
}

.empty_space {
  height: 7rem;
  width: 3rem;
  border: 1px solid #333;
}

.bg-red {
  background-color: rgb(255 86 86);
  border: none;
}

.bg-green {
  background-color: rgb(66 197 66);
  border: none;
}

.bg-partial {
  background-color: rgb(255 238 91);
  border: none;
}

.hide-downarraow {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  width: 32px;
  text-align: center;
  font-weight: bold;
}

.font-30 {
  font-size: 30px;
}


.decision_criteria_main {
  display: flex;
  flex-direction: column;
}

.decision_criteria_sub {
  display: flex;
  flex-direction: row;
  border: 1px solid #333;
  height: 3rem;
}

.descrion_subhading_textdropdown {
  width: 20%;
}

.decision_textb {
  width: 40%;
}

.decision_criteria_sub>.sub_heading {
  width: 40%;
  border-right: 1px solid #333;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.decision_criteria_sub>.sub_heading h6 {
  font-size: .9rem;
  color: #fff;
  text-align: center;
}

.decision_textb {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: .4rem;
  border-left: 1px solid #333;
}

.browse_btn {
  background: blue;
  border-radius: 8px;
  padding: 3px 15px;
  color: #fff;
  outline: none;
  border: none;
  margin-left: 1rem;
}

.descision_yes_no {
  height: 2.5rem;
  margin-top: .2rem;
  width: 4rem;
  margin-left: .2rem;

  color: #333;
  background: #fff;
}


.react-kanban-column-header {
  margin: 3px 20px;
}

.react-kanban-column {
  margin: 3px 20px;
  display: flex;
  flex-direction: row;
}

.react-kanban-card {
  margin: 3px 20px;
}


.css-g1d714-ValueContainer {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 2px 8px;
  -webkit-overflow-scrolling: touch;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  position: relative !important;
  z-index: 999 !important;
}

.update_follow {
  background: #fff;
  color: #333 !important;
  padding: 3px 10px;
  border-radius: 3px;
  border: none;
  outline: none;
  display: block;
  font-family: lato;
  width: 9rem;
  margin: 1rem;
  position: sticky;
  bottom: 2%;
  left: 38%;
  text-align: center;
}

.proccess_follow_main {
  color: #fff;
  border: 1px solid #333;
  font-size: 1rem;
  font-weight: 400;
  padding: 3px 10px;
  width: 100%;

}

.react-kanban-card__title {
  margin-bottom: 0rem;
  border: 1px dotted #fff;
  padding: 3px 10px;
  color: #fff;
  width: 10rem !important;
  height: 2rem;
  margin: 0 !important;
}

.react-kanban-card__title span {

  text-align: center !important;

}

[data-rbd-droppable-context-id="48"] {
  overflow-anchor: none;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}