.battlecard-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  
  .battlecard-file-input::before {
    content: 'Select battlecards pdf to upload';
    display: inline-block;
    background: #5160b4;
    border: 1px solid #5160b4;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    -webkit-user-select: none;
    cursor: pointer;
    font-size: 15px;
    color: #fff;
  }
  
  .battlecard-file-input:hover::before {
    border-color: #5160b4;
  }
  
  .battlecard-file-input:active::before {
    background: -webkit-linear-gradient(top, #5160b4, #5160b4);
  }