@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
.btn-table-commone {
  padding: 0.25rem 0.3rem !important;
  font-size: 0.7rem !important;
  border-radius: 0.4rem !important;
  margin-left: .3rem !important;
  display: flex;
  flex-direction: center;
  align-items: center;

}
.navbar_main_new_classs{
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  height: 50px;
  border-radius: 50px;
  padding-top: 5px;

}
.margin_left{
  margin-left: 60px !important;
}
.navbar {
  background-color: #38414a;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 999 !important;
  color: #fff;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.navbar_main_bg_color {
  background-color: #38414a !important;
}

.bg-dark {
  background-color: #38414a !important;
}

.topbarLeft {
  flex: 3 !important;

}

.menu-icon {
  height: 48px;
  width: 48px;
  float: right;
  margin-right: 10px;
}

.add-activity {
  float: left;
  margin-left: 10px;
}

.topbarImg {
  width: 40px;
  height: 40px;
  border-radius: 10%;
  object-fit: cover;
  cursor: pointer;
  margin-right: 20px;
}

.home-page {
  background-color: rgba(200, 200, 200, .2);
}

.bottomBarButtons {
  float: right;
  margin-right: 5px;
}

.navbar-links {
  margin: 12px 20px 12px 20px;
}

.mar-left-100 {
  margin-left: 60px;
}

.navbarAddBtn {
  margin-left: 10px;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar {
  width: 4px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.close {
  border-radius: 4px;
  color: #fff;
  border: 1px solid #cd485c;
  background: #cd485c;
  line-height: 30px;
}

.btn_submit_common_green {
  border-radius: 4px;
  color: #fff;
  border: 1px solid #16a085 !important;
  background: #16a085 !important;
  line-height: 30px;
}

.btn_submit_common_red {
  border-radius: 4px;
  color: #fff;
  border: 1px solid #cd485c !important;
  background: #cd485c !important;
  line-height: 30px;
}

.modal-dialog {
  min-width: 800px;
  margin: 1.75rem auto;
}

.opportunity-modal-dialog {
  min-width: 1000px;
  margin: 1.75rem auto;
}

.home-icon {
  font-size: 20px;
  padding-bottom: 2px;

}

.PrivateNotchedOutline-root-2 {
  top: 0px;
  top: 0px !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.75);
  background: white;
}

.MuiOutlinedInput-input {
  padding: 11px 13px !important;
}

.navbar-links {
  color: #fff;
}

.navbar-links:hover {
  color: #5160b4 !important;
}

.navbar-active {
  padding: 8px;
  color: #fff;
  border-bottom: 3px solid #fff;
}

.notification-icon {
  padding-top: 5px;
  bottom: 10px;
}

.logout-icon {
  margin-top: 5px;
  cursor: pointer;
}

.left-border-red {
  border-left-width: 3;
  border-left-color: 'red';
  border-left-style: 'solid';
}

.activity_chart_main {
  background-color: #fff;
  border-radius: 0 !important;
  width: 100%;
  height: auto;
  margin-top: 1.5rem;
}
.activity_chart_main1{
  background-color: #fff;
  border-radius: 0 !important;
  width: 100%;
  height: auto;
margin-top: 1.5rem;
  padding: 1.5rem 32rem;
}
.Opportunites_chart {
  background-color: #fff;
  border-radius: 0 !important;
  width: 100%;
  height: auto;

}

.revenue_maindiv {
  background-color: #fff;
  border-radius: 10px !important;
  width: 100%;
  height: 31.5rem;
}

.title_main {
  color: #4E61C4;
  font-family: "Poppins SemiBold";
  font-size: 30px;
  line-height: 28px;
  font-weight: bold;
  padding-top: 3rem;
  padding-bottom: 1rem;
}

.year_and_quater {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.year_and_quater select {
  border: 1px solid silver;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  color: #6E8898;
  font-family: "Poppins Medium";
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  border-radius: 5px;
  outline: none;
  font-weight: bold;

}

.sales_stages {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.css-1okebmr-indicatorSeparator {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  background-color: hsl(0, 0%, 80%);
  margin-bottom: 8px;
  margin-top: 8px;
  box-sizing: border-box;
  display: none;
}

.chart_button {
  background: #0d6efd;
  border: 1px solid #0d6efd;
  border-radius: 5px;
  color: #fff;
  outline: none;
  width: auto;
  height: 38px;

}

.chart_button1 {
  padding: 5px 15px;
  background: #0d6efd;
  border: 1px solid #0d6efd;
  border-radius: 5px;
  color: #fff;
  outline: none;
  width: auto;
}

.css-1wa3eu0-placeholder {
  color: hsl(0, 0%, 50%);
  margin-left: 0px !important;
  margin-right: 0px !important;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
  font-size: 14px !important;
}

.css-tlfecz-indicatorContainer {
  color: hsl(0, 0%, 80%);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 2px !important;
  -webkit-transition: color 150ms;
  transition: color 150ms;
  box-sizing: border-box;
}

.MuiInputBase-input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
  color: #808080 !important;
  font-size: 14px !important;
}

.all_feilds_css {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.box1 {
  width: 15%;
  margin-left: .5rem;
  margin-right: .5rem;
}

.box11 {
  width: 5%;
  margin-left: .5rem;
  margin-right: .5rem;
}

.box2 {
  width: 25%;
  margin-left: .5rem;
  margin-right: .5rem;
}

.title_opportunities {
  font-size: 1.5rem;
  color: #323a46;
  font-family: 'Poppins', sans-serif;
}

.row_gutter {
  --bs-gutter-x: 0.3rem !important;
}

.headerBand {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  background: #cd485c !important;
  color: #FFFFFF;
  width: 373px !important;
  border-radius: 4px !important;
  padding: 6px !important;
  cursor: pointer;
  margin-left: 0 !important;
}

.headerBandButtons {
  width: 373px !important;
}

.headerBand1 {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  background: #cd485c !important;
  color: #FFFFFF;
  width: 373px !important;
  border-radius: 4px !important;
  line-height: 30px !important;
  padding: 0 1rem;
  cursor: pointer;
  margin-left: .6rem !important;
}

.arrow_button {
  color: #fff;
  font-size: 1.3rem;
}

.filters__chevron {
  border-radius: 2px;
  transition: all 2 linear;
}

.filters__chevron.rotate {
  transform: rotate(180deg);
}


/* navbar css start here */
.btn_add_common {
  background: #5160b4 !important;
  border: 1px solid #5160b4 !important;
  margin-left: 1rem;
  color: #fff;
  outline: none !important;
  font-size: 1rem !important;
  text-decoration: none;
  display: inline-block !important;
  padding: 5px 15px !important;
}

.btn_add_common:focus,
.btn_add_common:active {
  outline: none !important;
  border: 1px solid #5160b4 !important;
  box-shadow: none !important;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
    align-items: center;
  }
}

@media screen and (max-width:991px) {
  .nav-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .headerBand {
    background: #cd485c !important;
    color: #FFFFFF;
    width: 100% !important;
    line-height: 30px !important;
    margin-right: 0rem !important;
  }
  .margin_left{
    margin-left: 5px !important;
  }
  .navbar_main_new_classs{
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: none;
    height: 100%;
    border-radius: 50px;
    padding-top: 5px;
  
  }
}

@media screen and (max-width:800px) {
  .modal-dialog {
    min-width: 100%;
    margin: 1.75rem auto;
  }

}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #1e2125;
  background-color: #e9ecef;
}

.navbar-active11 {
  background: #38414a;
  border-radius: 5px;
  padding: 10px 45px;
  color: #fff;
  text-align: center;
}

.navbar-links11 {
  margin: 20px 20px 20px 20px;
}

.navbar-links11:hover {
  color: #16a085;
}

.request_teche {
  float: right;
}

.MuiSvgIcon-root {
  font-size: 1.2rem !important;
}

.techie_activity {
  cursor: pointer !important;
}

.ClassName {
  background-color: gray;
}

.showopportunitiesmodal {
  background: rgba(0, 0, 0, .3);
  opacity: 1;
}

.right_to_left {
  direction: rtl;
  unicode-bidi: bidi-override !important;
  text-align: justify;

}

.container_tabs {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  background: #F5F5F5;
  word-break: break-all;
}

.lock_tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
}

.tab {
  position: relative;
  width: 60%;
  padding: 6px;
  outline: none;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  border: none !important;
  color: #4E4E4E !important;
  text-decoration: underline !important;
  margin-bottom: 1rem;
}

.button {
  border: none;
  color: #fff;
}

a {
  border: none;
  text-decoration: none !important;
}

.active_tab {
  background: #cd485c;
  box-shadow: 0px 4px 6px #00000038;
  border-radius: 4px;
  opacity: 1;
  color: #fff !important;
  text-decoration: none !important;
  cursor: none !important;

}

.tab_content {
  flex-grow: 1;
}

.content {
  padding: 20px 0;
  display: none;
  width: 100%;
  height: 100%;
}

.active_content {
  display: block;
}

.label_class {
  color: #333 !important;

}

.form_control11 {
  width: 100%;
  height: 2.2rem;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000004A;
  border: none;
  outline: none;
  border-radius: 4px;
  opacity: 1;

  margin: .5rem 0 1.5rem 0 !important;
}

.label_class select {
  width: 100%;
  margin-right: 0 !important;

}

.name_type {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.add_btn {
  padding: 10px 25px;
  border: none;
  outline: none;
  background: #7C6BDA 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  color: #fff;
  margin-left: 0rem;
  width: 10rem;
}

@media screen and (max-width:800px) {
  .container {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000026;
    opacity: 1;
  }

  .lock_tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
    flex-wrap: wrap;
  }

  .tab {
    position: relative;
    width: auto;
    background: none;
    outline: none;
    cursor: pointer;
    box-sizing: border-box;
    text-align: center;
    border: none !important;
    color: #4E4E4E;
    font-size: .8rem;
    margin-bottom: .5rem;

  }

  .button {
    border: none;
    color: #fff;
  }

  .active_tab {
    background: #cd485c;
    box-shadow: 0px 6px 15px #00000038;
    border-radius: 4px;
    opacity: 1;
  }

  /* organisation page css start here */
  .label_class {
    color: #333 !important;
    font-size: 1rem;

  }

  .form_control11 {
    width: 100%;
    height: 2rem;
    margin: .5rem 0 1.5rem 0 !important;
  }

  .label_class select {
    width: 100%;
    margin-right: 0 !important;

  }

  .name_type {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }


  .add_btn {
    padding: 10px 25px;
    border: none;
    outline: none;
    background: #7C6BDA 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    color: #fff;
    margin-left: 0rem;
    width: 10rem;
  }

  .form_section {
    padding: 0 .5rem !important;
  }
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  content: 'Attachment';
  display: inline-block;
  background: #5160b4;
  border: 1px solid #5160b4;
  border-radius: 3px;
  padding: 6px 10px;
  outline: none;
  /* -webkit-user-select: none; */
  cursor: pointer;
  font-size: 15px;
  color: #fff;
}

.custom-file-input:hover::before {
  border-color: #5160b4;
}

.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #5160b4, #5160b4);
}


.custom-file-input2::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input2::before {
  content: 'Upload PO ( * )';
  display: inline-block;
  background: #5160b4;
  border: 1px solid #5160b4;
  border-radius: 3px;
  padding: 6px 10px;
  outline: none;
  /* -webkit-user-select: none; */
  cursor: pointer;
  font-size: 15px;
  color: #fff;
}

.custom-file-input2:hover::before {
  border-color: #5160b4;
}

.custom-file-input2:active::before {
  background: -webkit-linear-gradient(top, #5160b4, #5160b4);
}



.custom-file-input3::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input3::before {
  content: 'Upload Quotation ( * )';
  display: inline-block;
  background: #5160b4;
  border: 1px solid #5160b4;
  border-radius: 3px;
  padding: 6px 10px;
  outline: none;
  /* -webkit-user-select: none; */
  cursor: pointer;
  font-size: 15px;
  color: #fff;
}

.custom-file-input3:hover::before {
  border-color: #5160b4;
}

.custom-file-input3:active::before {
  background: -webkit-linear-gradient(top, #5160b4, #5160b4);
}
.sales_stages {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.days_takan {
  float: right;
}

.css-u8eh2j-MuiStepConnector-root {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  position: absolute;
  top: 12px;
  left: calc(-80% + 20px) !important;
  right: calc(25% + 20px) !important;
}

.icons_folloup {
  font-size: 1.5rem;
}

.MuiToolbar-gutters {
  height: 20px !important
}

.MuiToolbar-regular {
  min-height: 40px !important;
  overflow: hidden;
}

.MuiTableCell-root {
  padding: 7px !important;
}

.navbar-leave-request {

  min-width: 150px !important;
}

.navbar-techie-meeting {

  min-width: 150px !important;
}

.navbar-case-study {

  min-width: 150px !important;
}

.navbar-setTarget {

  min-width: 150px !important;
}

.nav-home {

}


.badges {
  height: 20px;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.badges-md {
  height: 18px;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

.badge-purple {
  color: #fff;
  background-color: #9155fd;
}

.badge-yellow {
  color: #fff;
  background-color: #ffb400;
}

.badge-warning {
  color: #fff;
  background-color: #ff4c51;
}

.badge-success {
  color: #fff;
  background-color: #56ca00;
}

.badge-light {
  color: #212529;
  background-color: #d6dbe1;
}

.followup_icons {
  color: gray;
}

.followup_icons:hover {
  color: green;
  font-weight: bold;
}

.tasksIcons {
  cursor: pointer;
  color: grey;
}

.tasksIcons:hover {
  color: green;
}

.showLeadType {
  display: block;
}

.hideLeadType {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}


.css-h2qpui {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: -1.5rem;
  width: calc(100% + 1.5rem);
  margin-left: -1.5rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.MUIDataTableHeadCell-fixedHeader-558 {
  white-space: "nowrap";
  background: "#e6e6e6";
  font-weight: 'bold';
}

.css-oalq6z{
}


.editIcon{
  position: absolute;
  right: 0%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.resourceLink {
  position: relative;
  display: inline-block;
}

.resourceLink:hover .editIcon{
  display : block;
  cursor: pointer;
}

.resource-card{
      margin-top: 10px;
      position: relative;
      display: flex;
      flex-direction: row;
      min-width: 0;
      word-wrap: break-word;
      background-color: #fff;
      background-clip: border-box;
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-radius: 0.25rem;
      align-self: center;
      padding: .5rem 2rem;
 
}
.modal-90w{
  width: 500px !important;
}

.MuiPickerDTToolbar-toolbar {
  padding-left: 33px;
  padding-right: 27px;
  justify-content: space-around;
  height: 78px !important;
}

.MuiPickerDTToolbar-toolbar {
  padding-left: 16px;
  padding-right: 16px;
  justify-content: space-around;
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.MuiPickersTimePickerToolbar-toolbarAmpmLeftPadding {
  padding-left: 50px;
  height: 78px;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.MuiPickersDatePickerRoot-toolbar {
  align-items: flex-start;
  flex-direction: column;
  padding: 2.5rem;
}
.assignees_feilds{
  width: 19rem;
  margin: 1rem;
}

.css-qivjh0-MuiStepLabel-label {
  display: inline-block;
  width: 50px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 0.72rem !important;
  line-height: 1.13 !important;
  letter-spacing: 0.01071em;
  display: block;
  -webkit-transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  text-align: center;
}
.css-1mr67r1 {
  background-color: #ccc;
  z-index: 1;
  color: #fff;
  width: 45px !important;
  height: 45px !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-radius: 50%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.css-1dl46hm {
  width: 45px !important;
  height: 45px !important;

}
.css-u8eh2j-MuiStepConnector-root.MuiStepConnector-alternativeLabel {
  top: 21px !important;
}
.css-16ubnlw-MuiStepLabel-labelContainer {
  width: 50px !important;
  color: rgba(0, 0, 0, 0.6);
}
.css-ahj2mt-MuiTypography-root {
  margin: 0;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: .6rem !important;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  padding-left: 1.2rem !important;
}

.css-1hngkjo {
  width: 50px !important;
  color: rgba(0, 0, 0, 0.6);
}
.css-farnub {
  width: 50px !important;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 0.75rem !important;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  display: block;
  transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.css-9l3uo3 {
  margin: 0px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: .6rem !important;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  padding-left: 1.2rem !important;
}

.css-8vtk1h {
  flex: 1 1 auto;
  position: absolute;
  top: 12px;
  left: calc(-80% + 20px) !important;
  right: calc(50% + 20px) !important;
}

/* efficincy css start here */
.efficiency_filters{
  padding: 2rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.circular_chart{
  margin: 0.5rem 11rem;
}
.total_hrs_main{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin-bottom: 1rem;
padding-bottom: 1rem;
}
.total_hrs_main h6{
  font-size: 1.2rem;
  color: #333;
  font-weight: 400;
}
.total_hrs_main h2{
  font-size: 1.4rem;
  color: #333;
  font-weight: 600;
}
.assing_to_isales{
  margin: 0rem 2rem 0rem 0rem;
}
.MuiTableCell-head {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  line-height: 1.5rem;
  background:#e6e6e6 !important;
}
.MuiTableCell-root {
  padding: 7px !important;
}
.MuiCheckbox-root {
  color: rgba(0, 0, 0, 0.54);
  padding: 0px !important;
}
.assetlabels{
  background-color: rgb(187,45,59);
  font-weight: 600;
  color: aliceblue;
  width: 15%;
  text-align: center;
  height: 30px;
}

.updatelabels {
  background-color: rgb(187,45,59);
  color: aliceblue;
  font-weight: 600;
  width: 30%;
  text-align: center;
  height: 30px;
}
.updatelabels2{
  background-color: rgb(187,45,59);
  font-weight: 600;
  color: aliceblue;
  width: 25%;
  text-align: center;
  height: 30px;
}
.submitbutton{
  margin-left: 290px;
  background-color: rgb(220,53,69);
  border-color: rgb(220,53,69);
  border-radius: 4px;
  color: #fff;
 border: none;
 outline: none;
 padding: 6px 18px;
}
.nextbutton{
  margin-left: 690px;
  background-color: #5160B3;
  border-radius: 4px;
  border-color: #5160B3;
  color: white;
  border: none;
  outline: none;
  text-align: center;
  padding: 6px 22px;

}
.previousbutton{
  background-color: #5160B3;
  border-color: #5160B3;
  color: white;
  border-radius: 4px;
  border: none;
  outline: none;
  padding: 6px 18px;
 
}
.assetbuttons {
  margin-left: 700px;
   
}
.submitassetbutton{
  background-color: #5160B3;
  border-color: #5160B3;
  color: white;
  border-radius: 4px;
  border: none;
  outline: none;
  padding: 6px 18px;

}
.nextassetbutton{
  background-color: #5160B3;
  border-color: #5160B3;
  color: white;
  width: 60px;
  border-radius: 4px;
  border: none;
  outline: none;
  padding: 6px 18px;

}
.updateassetbuttons{
  margin-left: 700px;
  border: none;
  outline: none;
  padding: 6px 18px;
} 
.updatesubmitbutton{
  background-color: #5160B3;
  border-color: #5160B3;
  color: white;
  border-radius: 4px;
  border: none;
  outline: none;
  padding: 6px 18px;

}
.updatenextbutton{
  background-color: #5160B3;
  border-color: #5160B3;
  color: white;
  width: 60px;
  border-radius: 4px;
  border: none;
  outline: none;
  padding: 6px 18px;

}

.previos_submit_div{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.previos_submit_div1{
  float: right;
}
.webiste_modal{
  border-radius: 10px;
  position: absolute;
  top: 30%;
  left: 1%;
  min-width: 30%;
 
}
.rawdatacustomermodals{
  border-radius: 20px !important;
  
}


.updaate_common_btn{
  margin-right: 800px;
  background:red;
  border-color:red;
  border-radius: 2px;
}

.pad-0{
  padding: 0px !important;
}

.bg-blue{
  background-color: #38414a;
  color: #fff !important;
}

.bg-blue a{
  color:white !important;
  cursor: pointer;
}

.nav-links{
  color: #fff !important;
  cursor: 'pointer';
  border-bottom: 1px solid #fff !important;
  
}

.rdw-editor-wrapper{
  border:1.4px solid lightgray;
}


.css-1aiagyj-MuiStack-root{
  display: flex;
  flex-direction: row;
  width: 10%;  
}

.MuiTablePagination-root .CustomFooter-root-10440{
  display: flex !important;
    justify-content: flex-end;
}

.MuiTableCell-root .MuiTableCell-footer{
  display: flex !important;
  justify-content: flex-end;
}

.card-elements{
  width: 11% !important;
  margin-top: 10px !important;
  margin-left: 15px;
}

.roi-table {
  border-collapse: collapse;
}

.roi-table td, .roi-table th {
  border: 1px solid black;
}
















.calendar_tool{
  display: flex;
  background-color: #f2f2f2;
  justify-content: space-between;
}









calendar-toolbar {
  display: flex;
 
  
  margin-left: 1rem !important;
  background-color: #f2f2f2;
}

.calendar-toolbar button {
  margin: 5px;
  padding: 3px 15px;
  font-size: 14px;
border: 1px solid rgb(88, 86, 86) !important;
  color: black;
 
  border-radius: 5px;
  cursor: pointer;
}
.calendar-toolbar span{
  
  padding: 8px 15px;
  font-size: 20px;
  border-radius: 5px;
  cursor: pointer;
  padding-left: 80px;
}


.calendar-toolbar button:hover {
 
  margin: 5px;
  padding: 3px 15px;
  font-size: 14px;
  border: 1px solid rgb(72, 70, 70) !important;
  background-color: rgb(187, 187, 243);
  outline-color: red;
  color: black;
  
  border-radius: 5px;
  cursor: pointer;
 
}
.calendar_tool{
  display: flex;
  background-color: #f2f2f2;
  justify-content: space-between;
}
.splt_btn{
  margin-right: 3rem !important;

 padding-top: 0.5rem !important;
 padding-bottom: 0.5rem !important;
}
/* .calendar-toolbar button:nth-child(-n + 4) {
  background-color: rgb(93, 90, 90);
  color: #fff;


} */
.calendar-toolbar button:nth-child(1) {
  background-color:  #0056b3; 
  color: white;
  padding: 7px 20px;
}
.calendar_mo{
  border-radius: 10%;
 
}
.date_picker{
  padding: 5px 13px;
  z-index: -999px !important;
}
.drop_btn{
  padding: 5px 25px;
  font-size: 14px;
  background-color:#2f83dd;
}
.button-group .addevent {
  padding: 10px 20px; /* Adjust the padding values as needed */
  background-color: rebeccapurple;
}



.custom-modal {
  width: 200px !important;
 
justify-content: center;
}

.header_modal {
  display: flex;
 /* This will push the button to the right */
}

.bottomBarButtons {
  /* margin-left: 560px !important; */
  height: 30px; /* Set the desired height in pixels */
  /* Remove the float property */
}
.modal-centered{
  width: 300px !important;
}

.meeting_url {
  display: flex;
  /* border: 2px solid gray; */
}
.linkdata{
  /* border: #0056b3; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 40ch;
}


.copy-icon {
  margin-right: 30px; /* Adjust the margin as needed */
}


.copied-icon {
 color: blue !important;
}
.copy-icon{
  color: black !important;
}


.custom_header_-title {
  display: flex !important;
  font-size: 18px; /* Adjust the font size as needed */
}
.custom_header_title_update{
  display: flex !important;
  font-size: 17px !important;  
}
.blue-text {
  background-color: blue !important;
  color: white !important;
  border: none;
  padding: 4px 5px;
  border-radius: 3%;
}



.custom-modal-class {

  display: flex;
  /* max-width: 20%; */
  justify-content: center; 

  margin: 0 auto; 
  border: none !important;

  
}

.modal-width {
  min-width: 30% 
}



.parent-div {
  display: flex;
  justify-content: center; /* Center the child elements horizontally */
  align-items: center; /* Center the child elements vertically */
  /* border: 2px solid red; */
  position: relative;
  height: 22rem;
}

.loader {
  background-image: url("../../../public/images/crmloader.gif");
  background-repeat: no-repeat;
  background-position: center center; /* Center the background image */
  height: 15rem;
  width: 30%;
  object-fit: contain !important;
}

small{
  font-size:1.1rem !important;
}
.modal-title {
  white-space: noWrap !important;
  flex: 1 !important;
}


/* search product data css */


.search_products_field{
  /* width: 40%; */
  border: 1px solid #333;
  color: #333 !important;
}
.MuiInputBase-input{
  color: #333 !important;
}
.dropdown-content12{
  /* width: 40%; */
  margin: .5rem 0;
  padding: .5rem 0;
}
.typography_list{
  margin: 0 !important;
  padding: 0 !important;
}
.styel_summary {
  padding: 0 10px !important;
  min-height: 1px !important;
}
.subProducts_list{
  margin: 0 !important;
  padding:0 0 0 5px !important;
}

.subProducts_list li{
  text-decoration: none;
  font-style: none;
  list-style-type: none;
  cursor: pointer;
  gap: 5px;
}
.subProducts_list li:hover{
  /* background: #0080FF80; */
  width: 100%;
  flex: 1;
  cursor: pointer;
}
.MuiAccordionDetails-root {
  display: flex;
  padding: 0px 10px 5px 10px !important;
}
.accordion-summary {
  display: flex;
  align-items: center;
}

.accordion-text {
  margin-right: auto; /* This pushes the text to the start */
}
.search-container{
  position: relative;
}
.dropdown_content12{
  position: absolute;
  top: 100%;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
  padding: 15px 5px;
  z-index: 999;
  height: 25rem;
  overflow-y: scroll;
}
.dropdown_content1234{
  position: absolute;
  top: 100%;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
  padding: 15px 5px;
  z-index: 999;
  height: 25rem;
  width: 25rem;
  overflow-y: scroll;
}
.checkbox-label {
  margin-left: 5px; /* Adjust the value to your preference */
}
.MuiAccordionSummary-root {
  /* background: #f6f6f6 !important; */
}

.custom-smaller-modal {
  max-width: 1100px !important; /* Adjust the width as needed */
  /* width: 80%; */
  margin: 0 auto;
}
.card_layout{
  width: 25% !important;
}

.otpTimerbox {
  text-align: right;
}

.no-select {
  user-select: none; /* Standard syntax */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
