@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.parent-div {
  display: flex;
  justify-content: center; /* Center the child elements horizontally */
  align-items: center; /* Center the child elements vertically */
  /* border: 2px solid red; */
  position: relative;
  height: 22rem;
}

.loader {
  background-image: url("../../../public/images/crmloader.gif");
  background-repeat: no-repeat;
  background-position: center center; /* Center the background image */
  height: 10rem;
  width: 30%;
  object-fit: contain !important;
}


.fullscreen {
  background-color: white;
}

.common_button_charts {
  position: relative;
  background: #cd485c;
  border: 1px solid #cd485c;
  border-radius: 4px;
  outline: none;
  width: 2.4rem;
  height: 2.4rem;

}

.common_button_charts1 {
  position: relative;
  background: #16a085;
  border: 1px solid #16a085;
  border-radius: 4px;
  outline: none;
  width: 2.4rem;
  height: 2.4rem;

}

.common_chart_icons {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #9f9f9f;
  font-size: 1.7rem;
}

.common_chart_icons11 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 1.7rem;
}

.common_chart_icons33 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #9f9f9f;
  font-size: 1.4rem;
}

.common_chart_icons22 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 1.4rem;
}

.select__control {
  position: relative !important;
}

.select__menu {
  /* width: 9rem !important;
  position: absolute;
  left: 50%;
  top: -10% !important;
  transform: translate(-50%, -50%); */
  font-size: .7rem !important;
  position: relative;
  z-index: 999 !important;
}

.compant_title {
  color: #323a46;
  line-height: 7px;
  font-family: 'Poppins', sans-serif;
}

/* tasklist css start here */
.btn_leads_task {
  /* background: #485ec4;
  border: 1px solid #485ec4; */
  background: #5160b4;
  border: 1px solid #5160b4;
  color: #fff;
  text-align: center;
  font-size: .8rem;
  /* width:7.8rem;
  height: 3rem; */
  border-radius: 4px;
  /* font-family: 'Poppins', sans-serif; */
}

.btn_leads_task11 {
  background: #F5F5F5 0% 0% no-repeat padding-box;
  border: 1px solid #F5F5F5;
  color: #333;
  opacity: 1;
  text-align: center;
  font-size: .8rem;
  border-radius: 4px;
}


.task_description {
  font-size: 14px;
  max-height: 70px;
  overflow: auto;
}


.task_title_main {
  color: #333;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
}

.common_fontfamily {
  font-family: 'Poppins', sans-serif;
}

.scrollbar {
  height: 300px;
  max-height: 440px;
  min-height: 440px;
  padding: '15px';
  background: #fff;
  overflow-y: scroll;
}


#style-10::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

#style-10::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

#style-10::-webkit-scrollbar-thumb {
  background-color: #5a636b;
  border-radius: 10px;
  background-image: -webkit-linear-gradient(90deg,
      rgba(0, 0, 0, .2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(0, 0, 0, .2) 50%,
      rgba(0, 0, 0, .2) 75%,
      transparent 75%,
      transparent)
}

.upadte_common_btn {
  background: #5160b4 !important;
  border: 1px solid #5160b4 !important;
  color: #fff !important;
  outline: none;
}


.tab1 {
  position: relative;
  background: #F5F5F5 0% 0% no-repeat padding-box;
  border: 1px solid #333;
  border-radius: 4px;
  opacity: 1;
  outline: none;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  border: none !important;
  color: #333;
  outline: none;
  width: 2.4rem;
  height: 2.4rem;
}

.active_tab1 {
  position: relative;
  background: #5160b4;
  border: 1px solid #5160b4;
  border-radius: 4px;
  outline: none;
  width: 2.4rem;
  height: 2.4rem;
}
.active_tab_allreports {
  position: relative;
  background: #5160b4 !important;
  border: 1px solid #5160b4 !important;
  border-radius: 4px;
  outline: none;
  color: #fff !important;
  /* width: 2.4rem;
  height: 2.4rem; */
}

.datepicker {
  height: 50px;
}

.tooltip1 {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip1 .tooltiptext1 {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
}

.tooltip1:hover .tooltiptext1 {
  visibility: visible;
}
