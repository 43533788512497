 .rowdatafiles::-webkit-file-upload-button {
  visibility: hidden;
}

.rowdatafiles::before {
  content: 'Select Excel file to upload';
  display: inline-block;
  background: #5160b4;
  border: 1px solid #5160b4;
  border-radius: 3px;
  padding: 6px 9px;
  outline: none;
  -webkit-user-select: none;
  cursor: pointer;
  font-size: 15.4px;
  color: #fff;
}

.rowdatafiles:hover::before {
  border-color: #5160b4;
}

.rowdatafiles:active::before {
  background: -webkit-linear-gradient(top, #5160b4, #5160b4);
}

  
  .rowdatafiles::-webkit-file-upload-button {
    visibility: hidden;
  }
  
  .rowdatafiles::before {
    content: 'Select your Excel File';
    display: inline-block;
    background: #5160b4;
    border: 1px solid #5160b4;
    border-radius: 4px;
    padding: 6px 9px;
    outline: none;
  
    -webkit-user-select: none;
    cursor: pointer;
    font-size: 19px;
    color: #fff;
  }
  
  .rowdatafiles:hover::before {
    border-color: #5160b4;
  }
  
  .rowdatafiles:active::before {
    background: -webkit-linear-gradient(top, #5160b4, #5160b4);
  }

  .excelfilename{
    color:green;
  }
  .rowdatabuttonbox{

    margin-left: 90px;
  
  }
 
  .rawdatacustomermodals{
    width: 20px;
  }

