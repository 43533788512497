/* roitable.css */
.table_second_layout{
    background: #fff;
    /* box-shadow: 5px 10px #888888; */
    border-radius: 10px;
    /* padding: 8px; */
    width:100%;
}
.comapany_layout{
    background-color: #fff;
    box-shadow:  0px 3px 6px #00000029;
    padding: 10px;
    border-radius: 10px;
    gap: 20px;
}
.comapny_name_is{
  margin-top: 1rem;
}
.table-with-border {
    border-collapse: collapse;
    border: 1px solid #333;
  }
  
  .table-with-border th,
  .table-with-border td {
    border: 2px solid #333;
    padding: 5px;
    border-radius: 4px;
    /* text-align: center; */
  }
  .roi-table tr{
    font-weight: bold;
  }
  .header-cell {
    width: auto;
  }
  .header-cell1234 {
    width: 60px;
  }
  .rotate-text  {
    /* transform: rotate(270deg); */
    writing-mode: vertical-lr; /* Rotate text vertically */
    transform: rotate(180deg); /* Adjust rotation to make text upright */
    text-align: center; /* Center the text within the rotated cell */
  }

  .text-align-right {
    text-align: right;
    float: right;
  }
.total_direct_saving_table td{
    color: darkred !important;
    font-weight: bold;
}
.total_direct_saving_table td:nth-child(2){
   background-color: lightgreen;
   /* float: right; */
}
.total_direct_saving_table td span:nth-child(2){
    /* background-color: lightgreen; */
    float: right;
 }
.number_input_layout{
    flex: 1;
    width: 100%;
    height: 100%;
    border: none !important;
    outline: none;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    text-align: right;
}
.common_row_bg_green{
    background-color: lightgreen;
    font-weight: bold;
}
.common_row_bg_blue{
    background-color: lightblue;
    font-weight: bold;
}
.common_row_bg_lightyellow{
    background-color: lightyellow;
    font-weight: bold;  
}
.common_gary_bg{
    background-color: lightgray;
    font-weight: bold;  
}
.common_bg_orange{
    background-color: #FAC898;
}
.common_dark_red{
    color: darkred;
}
/* .common_row_bg_green td:nth-child(3){
    background: yellow;
} */