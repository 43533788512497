.add_new_contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.add_new_contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.upadte_modal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
}

.card_main {
  background: whitesmoke;
  width: 7rem;
  padding-top: 5px;
}

.card-footer {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.card {
  margin-left: 46%;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  align-self: center;
}
.common_center_div{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

