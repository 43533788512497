.profile {
    width: 100vw;
    height: auto;
    background-color: #f0f2f5;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
}

.profile {
    display: flex;
}

.profileRight {
    flex: 9;
}

.profileCover {
    height: 320px;
    position: relative;
}

.profileCoverImg {
    width: 100%;
    height: 250px;
    object-fit: cover;

}

.profileUserImg {
    width: 120px;
    height: 120px;
    border-radius: 10%;
    object-fit: cover;
    left: 0;
    right: 0;
    margin: auto;
    border: 3px solid #fff;
    cursor: none;
}

.profileInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.profileInfoName {
    font-size: 24px;
}

.profileInfoDesc {
    font-weight: 300;
}

.profileRightBottom {
    display: flex;
}

#input[type="file"] {
    display: none;
}

.label {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
}

.image-upload {
    margin: auto;
    width: 200px;
    height: 30px;
    color: white;
    border-radius: 4px;
    background-color: black;
    text-align: center;
    cursor: pointer;
    margin-top: 1rem;
}

.registerBox {
    height: 800px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}